import axios from "axios";

const axiosClient = axios.create({ baseURL: process.env.REACT_APP_BACKEND_URL });

// axiosClient.defaults.withCredentials = true;
// axiosClient.defaults.withXSRFToken = true;

axiosClient.interceptors.request.use((config) => {
    config.headers.Authorization = `Bearer ${localStorage.getItem("ACCESS_TOKEN")}`;

    return config;
});

axiosClient.interceptors.response.use((response) => {

    return response;

}, (error) => {

    switch (error.response.status) {
        case 401:
            localStorage.removeItem("ACCESS_TOKEN");
            localStorage.removeItem("ACCESS_USER");
            window.location.replace("/login");
            break;

        case 403:
            window.location.replace("/unauthorized");
            break;

        default:
            throw error;
    }
});

export default axiosClient;