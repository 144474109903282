import React from 'react'

const Footer = () => {
  return (
    <footer className="main-footer text-center">
      Copyright © 2023-2023 <a href="https://www.inventor.sa"><strong className="text-success">Inventor.sa</strong></a>. All rights reserved.
    </footer>
  )
}

export default Footer