import { useLayoutEffect, useRef, useState } from "react";
import axiosClient from "../../Helpers/Axios-Client";
import DataTable from 'react-data-table-component';
import Swal from 'sweetalert2'
import { toast } from 'react-toastify';
import { Link } from "react-router-dom";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { useMainContext } from "../../Contexts/ContextProvider";
import 'react-tabs/style/react-tabs.css';

function Roles() {

    const { user } = useMainContext();

    const emptyRow = {
        id: null,
        is_active: 1,
        ar_name: "",
        en_name: "",
        ar_desc: "",
        en_desc: "",

        countries_view: 0,
        countries_add: 0,
        countries_edit: 0,
        countries_delete: 0,
        
        roles_view: 0,
        roles_add: 0,
        roles_edit: 0,
        roles_delete: 0,

        users_view: 0,
        users_add: 0,
        users_edit: 0,
        users_delete: 0,

        companies_view: 0,
        companies_add: 0,
        companies_edit: 0,
        companies_delete: 0,

        printers_view: 0,
        printers_add: 0,
        printers_edit: 0,
        printers_delete: 0,

        employees_view: 0,
        employees_add: 0,
        employees_edit: 0,
        employees_delete: 0,

        food_categories_view: 0,
        food_categories_add: 0,
        food_categories_edit: 0,
        food_categories_delete: 0,

        food_view: 0,
        food_add: 0,
        food_edit: 0,
        food_delete: 0,

    };

    const [roles, setRoles] = useState();
    const [role, setRole] = useState(emptyRow);

    const openDetailsModal_Ref = useRef();
    const closeDetailsModal_Ref = useRef();

    const columns = [
        {
            name: '#',
            selector: row => row.id,
            sortable: true,
            center: true,
            width: "80px",
        },
        {
            name: 'Active',
            selector: row => row.is_active,
            sortable: true,
            center: true,
            cell: row => (row.is_active) ? <span className="badge badge-success" >Active</span> : <span className="badge badge-danger" >Inactive</span>,

        },
        {
            name: 'Name',
            selector: row => row.en_name,
            sortable: true,

        },
        {
            name: 'Description',
            selector: row => row.en_desc,
            sortable: true,
            center: true,
        },
        {
            name: 'Created at',
            selector: row => row.created_at,
            sortable: true,
            center: true,

        },
        {
            name: '',
            selector: row => row.actions,
            center: true,
            width: "300px",
            cell: row => <>
                {user.role && user.role.roles_edit ? <button onClick={ev => onView(row)} type="button" className="btn btn-primary btn-sm text-white mr-2" ><i className="fa fa-edit" /> Edit</button> : "" }
                {user.role && user.role.roles_delete ? <button onClick={ev => onDelete(row)} type="button" className="btn btn-danger btn-sm text-white mr-2"><i className="fa fa-trash-alt" /> Delete</button> : ""}
            </>,
        },
    ];

    useLayoutEffect(() => {
        getRoles();
    }, []);

    const getRoles = () => {

        axiosClient.get("/roles").then((response) => {

            process.env.NODE_ENV === "development" && console.log("Roles", response.data);
            setRoles(response.data);

        }).catch((error) => {

            process.env.NODE_ENV === "development" && console.log(error);
        });

    }

    const onView = (selectedRow) => {

        if (selectedRow) {
            setRole(selectedRow);
        }
        else {
            setRole(emptyRow);
        }

        openDetailsModal_Ref.current.click();

    }

    const onSave = () => {

        if (role.id) {
            process.env.NODE_ENV === "development" && console.log("Edit Role", role);

            axiosClient.put(`/roles/${role.id}`, role).then((response) => {

                process.env.NODE_ENV === "development" && console.log("Edit Role Response : ", response)

                setRoles(response.data);
                closeDetailsModal_Ref.current.click();


                Swal.fire({
                    position: 'center',
                    icon: 'success',
                    title: `${role.en_name} has been updated`,
                    showConfirmButton: false,
                    timer: 1000
                });


            }).catch((error) => {
                if (error.response && error.response.status === 422) {
                    error.response.data.errors && Object.keys(error.response.data.errors).map(key => (
                        toast.error(error.response.data.errors[key][0])
                    ));
                }
                else {
                    process.env.NODE_ENV === "development" && console.log("Edit Role Error :", error.response);
                }

            });
        }
        else {

            process.env.NODE_ENV === "development" && console.log("Add Role", role);

            axiosClient.post(`/roles`, role).then((response) => {
                process.env.NODE_ENV === "development" && console.log("Add Role Response : ", response);

                setRoles(response.data);

                closeDetailsModal_Ref.current.click();

                Swal.fire({
                    position: 'center',
                    icon: 'success',
                    title: `${role.en_name} has been added`,
                    showConfirmButton: false,
                    timer: 1000
                });

            }).catch((error) => {


                if (error.response && error.response.status === 422) {
                    error.response.data.errors && Object.keys(error.response.data.errors).map(key => (
                        toast.error(error.response.data.errors[key][0])
                    ));
                }
                else {
                    process.env.NODE_ENV === "development" && console.log("Add Role Error:", error.response);
                }


            });
        }

    }

    const onDelete = (selectedRow) => {

        process.env.NODE_ENV === "development" && console.log("Delete Role", selectedRow);

        Swal.fire({
            title: `Are you sure you want to delete ${selectedRow.en_name} ?`,
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#d33',
            cancelButtonColor: '#28a745',
            confirmButtonText: 'Yes, delete it!',
            reverseButtons: true
        }).then((result) => {

            if (result.isConfirmed) {

                axiosClient.delete(`/roles/${selectedRow.id}`).then((response) => {

                    process.env.NODE_ENV === "development" && console.log("Delete Role Response ", response);
                    setRoles(response.data);

                    Swal.fire({
                        position: 'center',
                        icon: 'success',
                        title: `${role.en_name} has been deleted`,
                        showConfirmButton: false,
                        timer: 1000
                    });

                }).catch((error) => {

                    process.env.NODE_ENV === "development" && console.log("Delete Role Error", error);
                });

            }
        })

    }
    
    return (
        <div className="content-wrapper">

            <div className="content-header">
                <div className="container-fluid">
                    <div className="row mb-2">
                        <div className="col-sm-6">
                            <h1 className="m-0">Roles</h1>
                        </div>

                        <div className="col-sm-6">
                            <ol className="breadcrumb float-sm-right">
                                <li className="breadcrumb-item"><Link to={"/"} className="text-success">Dashboard</Link></li>
                                <li className="breadcrumb-item active">Roles</li>
                            </ol>
                        </div>

                    </div>

                </div>

            </div>

            <div className="content">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12">
                            <div className="card">
                                <div className="card-header text-right">
                                    {user.role && user.role.roles_add ? <button onClick={ev => onView(null)} type="button" className="btn btn-success btn-lg" ><i className="fa fa-plus-circle" /> New Role</button> : ""}
                                </div>

                                <div className="card-body">
                                    <div className="row ">
                                        <div className="form-group offset-sm-10 col-sm-2 ">
                                            <div className="input-group">
                                                <input type="search" className="form-control " placeholder="Search" />
                                                <div className="input-group-append">
                                                    <button className="btn btn-default">
                                                        <i className="fa fa-search" />
                                                    </button>
                                                </div>
                                            </div>

                                        </div>

                                    </div>

                                    <DataTable className="table table-bordered" columns={columns} data={roles} pagination />

                                </div>
                            </div>

                        </div>

                    </div>
                </div>
            </div>

            <button style={{ display: "none" }} ref={openDetailsModal_Ref} type="button" className="btn btn-success btn-lg" data-toggle="modal" data-target="#DetailsModal"><i className="fa fa-plus-circle" /> </button>
            <div className="modal fade" id="DetailsModal" data-backdrop='static' tabIndex={-1} role="dialog" aria-labelledby="DetailsModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered" role="document">
                    <div className="modal-content">

                        <div className="modal-header">
                            <h5 className="modal-title text-center" id="exampleModalLabel">{role && role.id ? "Edit Role" : "Add Role"}</h5>
                            <button ref={closeDetailsModal_Ref} type="button" className="close" data-dismiss="modal" aria-label="Close" >
                                <span aria-hidden="true">×</span>
                            </button>
                        </div>

                        <div className="modal-body">

                            <Tabs>
                                <TabList>
                                    <Tab>General</Tab>
                                    <Tab>Permissions</Tab>
                                </TabList>

                                <TabPanel>
                                    <div className="col-sm-12">
                                        <form className="form-horizontal">
                                            <div className="form-group row">
                                                <div className="offset-sm-4 col-sm-9">
                                                    <div className="form-check">

                                                        <input className="form-check-input"
                                                            checked={role.is_active}
                                                            onChange={ev => setRole({ ...role, is_active: Number(ev.target.checked) })}
                                                            type="checkbox"
                                                            id="ckActive" />

                                                        <label className="form-check-label" htmlFor="ckActive">Active</label>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="form-group row">
                                                <label htmlFor="txtArName" className="col-sm-4 col-form-label">Name ( Arabic )</label>
                                                <div className="col-sm-8">

                                                    <input type="text"
                                                        value={role.ar_name}
                                                        onChange={ev => setRole({ ...role, ar_name: ev.target.value })}
                                                        className="form-control"
                                                        id="txtArName"
                                                        placeholder="Arabic Name" />
                                                </div>
                                            </div>

                                            <div className="form-group row">
                                                <label htmlFor="txtEnName" className="col-sm-4 col-form-label">Name ( English )</label>
                                                <div className="col-sm-8">
                                                    <input type="text"
                                                        value={role.en_name}
                                                        onChange={ev => setRole({ ...role, en_name: ev.target.value })}
                                                        className="form-control"
                                                        id="txtEnName"
                                                        placeholder="English Name" />
                                                </div>
                                            </div>

                                            <div className="form-group row">
                                                <label htmlFor="txtArDesc" className="col-sm-4 col-form-label">Desc ( Arabic )</label>
                                                <div className="col-sm-8">
                                                    <textarea className="form-control" id="txtArDesc" rows="3" value={role.ar_desc} onChange={ev => setRole({ ...role, ar_desc: ev.target.value })}></textarea>
                                                </div>
                                            </div>

                                            <div className="form-group row">
                                                <label htmlFor="txtEnDesc" className="col-sm-4 col-form-label">Desc ( English )</label>
                                                <div className="col-sm-8">
                                                    <textarea className="form-control" id="txtEnDesc" rows="3" value={role.en_desc} onChange={ev => setRole({ ...role, en_desc: ev.target.value })}></textarea>
                                                </div>
                                            </div>

                                        </form>
                                    </div>
                                </TabPanel>

                                <TabPanel>
                                    <div className="col-sm-12">
                                        <table className="table">
                                            <thead>
                                                <tr>
                                                    <th></th>
                                                    <th className="text-center">View</th>
                                                    <th className="text-center">Add</th>
                                                    <th className="text-center">Edit</th>
                                                    <th className="text-center">Delete</th>
                                                </tr>
                                            </thead>

                                            <tbody>
                                                <tr>
                                                    <td><b>Countries</b></td>
                                                    <td className="text-center "><input checked={Number(role.countries_view)} onChange={ev => setRole({ ...role, countries_view: Number(ev.target.checked) })} className="align-middle" type="checkbox" /></td>
                                                    <td className="text-center "><input checked={Number(role.countries_add)} onChange={ev => setRole({ ...role, countries_add: Number(ev.target.checked) })} className="align-middle" type="checkbox" /></td>
                                                    <td className="text-center "><input checked={Number(role.countries_edit)} onChange={ev => setRole({ ...role, countries_edit: Number(ev.target.checked) })} className="align-middle" type="checkbox" /></td>
                                                    <td className="text-center "><input checked={Number(role.countries_delete)} onChange={ev => setRole({ ...role, countries_delete: Number(ev.target.checked) })} className="align-middle" type="checkbox" /></td>
                                                </tr>

                                                <tr>
                                                    <td><b>Roles</b></td>
                                                    <td className="text-center "><input checked={Number(role.roles_view)} onChange={ev => setRole({ ...role, roles_view: Number(ev.target.checked) })} className="align-middle" type="checkbox" /></td>
                                                    <td className="text-center "><input checked={Number(role.roles_add)} onChange={ev => setRole({ ...role, roles_add: Number(ev.target.checked) })} className="align-middle" type="checkbox" /></td>
                                                    <td className="text-center "><input checked={Number(role.roles_edit)} onChange={ev => setRole({ ...role, roles_edit: Number(ev.target.checked) })} className="align-middle" type="checkbox" /></td>
                                                    <td className="text-center "><input checked={Number(role.roles_delete)} onChange={ev => setRole({ ...role, roles_delete: Number(ev.target.checked) })} className="align-middle" type="checkbox" /></td>
                                                </tr>
                                                <tr>
                                                    <td><b>Users</b></td>
                                                    <td className="text-center "><input checked={Number(role.users_view)} onChange={ev => setRole({ ...role, users_view: Number(ev.target.checked) })} className="align-middle" type="checkbox" /></td>
                                                    <td className="text-center "><input checked={Number(role.users_add)} onChange={ev => setRole({ ...role, users_add: Number(ev.target.checked) })} className="align-middle" type="checkbox" /></td>
                                                    <td className="text-center "><input checked={Number(role.users_edit)} onChange={ev => setRole({ ...role, users_edit: Number(ev.target.checked) })} className="align-middle" type="checkbox" /></td>
                                                    <td className="text-center "><input checked={Number(role.users_delete)} onChange={ev => setRole({ ...role, users_delete: Number(ev.target.checked) })} className="align-middle" type="checkbox" /></td>
                                                </tr>
                                                <tr>
                                                    <td><b>Companies</b></td>
                                                    <td className="text-center "><input checked={Number(role.companies_view)} onChange={ev => setRole({ ...role, companies_view: Number(ev.target.checked) })} className="align-middle" type="checkbox" /></td>
                                                    <td className="text-center "><input checked={Number(role.companies_add)} onChange={ev => setRole({ ...role, companies_add: Number(ev.target.checked) })} className="align-middle" type="checkbox" /></td>
                                                    <td className="text-center "><input checked={Number(role.companies_edit)} onChange={ev => setRole({ ...role, companies_edit: Number(ev.target.checked) })} className="align-middle" type="checkbox" /></td>
                                                    <td className="text-center "><input checked={Number(role.companies_delete)} onChange={ev => setRole({ ...role, companies_delete: Number(ev.target.checked) })} className="align-middle" type="checkbox" /></td>
                                                </tr>
                                                <tr>
                                                    <td><b>Printers</b></td>
                                                    <td className="text-center "><input checked={Number(role.printers_view)} onChange={ev => setRole({ ...role, printers_view: Number(ev.target.checked) })} className="align-middle" type="checkbox" /></td>
                                                    <td className="text-center "><input checked={Number(role.printers_add)} onChange={ev => setRole({ ...role, printers_add: Number(ev.target.checked) })} className="align-middle" type="checkbox" /></td>
                                                    <td className="text-center "><input checked={Number(role.printers_edit)} onChange={ev => setRole({ ...role, printers_edit: Number(ev.target.checked) })} className="align-middle" type="checkbox" /></td>
                                                    <td className="text-center "><input checked={Number(role.printers_delete)} onChange={ev => setRole({ ...role, printers_delete: Number(ev.target.checked) })} className="align-middle" type="checkbox" /></td>
                                                </tr>

                                                <tr>
                                                    <td><b>Employees</b></td>
                                                    <td className="text-center "><input checked={Number(role.employees_view)} onChange={ev => setRole({ ...role, employees_view: Number(ev.target.checked) })} className="align-middle" type="checkbox" /></td>
                                                    <td className="text-center "><input checked={Number(role.employees_add)} onChange={ev => setRole({ ...role, employees_add: Number(ev.target.checked) })} className="align-middle" type="checkbox" /></td>
                                                    <td className="text-center "><input checked={Number(role.employees_edit)} onChange={ev => setRole({ ...role, employees_edit: Number(ev.target.checked) })} className="align-middle" type="checkbox" /></td>
                                                    <td className="text-center "><input checked={Number(role.employees_delete)} onChange={ev => setRole({ ...role, employees_delete: Number(ev.target.checked) })} className="align-middle" type="checkbox" /></td>
                                                </tr>
                                                <tr>
                                                    <td><b>Food Categories</b></td>
                                                    <td className="text-center "><input checked={Number(role.food_categories_view)} onChange={ev => setRole({ ...role, food_categories_view: Number(ev.target.checked) })} className="align-middle" type="checkbox" /></td>
                                                    <td className="text-center "><input checked={Number(role.food_categories_add)} onChange={ev => setRole({ ...role, food_categories_add: Number(ev.target.checked) })} className="align-middle" type="checkbox" /></td>
                                                    <td className="text-center "><input checked={Number(role.food_categories_edit)} onChange={ev => setRole({ ...role, food_categories_edit: Number(ev.target.checked) })} className="align-middle" type="checkbox" /></td>
                                                    <td className="text-center "><input checked={Number(role.food_categories_delete)} onChange={ev => setRole({ ...role, food_categories_delete: Number(ev.target.checked) })} className="align-middle" type="checkbox" /></td>
                                                </tr>

                                                <tr>
                                                    <td><b>Food</b></td>
                                                    <td className="text-center "><input checked={Number(role.food_view)} onChange={ev => setRole({ ...role, food_view: Number(ev.target.checked) })} className="align-middle" type="checkbox" /></td>
                                                    <td className="text-center "><input checked={Number(role.food_add)} onChange={ev => setRole({ ...role, food_add: Number(ev.target.checked) })} className="align-middle" type="checkbox" /></td>
                                                    <td className="text-center "><input checked={Number(role.food_edit)} onChange={ev => setRole({ ...role, food_edit: Number(ev.target.checked) })} className="align-middle" type="checkbox" /></td>
                                                    <td className="text-center "><input checked={Number(role.food_delete)} onChange={ev => setRole({ ...role, food_delete: Number(ev.target.checked) })} className="align-middle" type="checkbox" /></td>
                                                </tr>
                                            </tbody>

                                        </table>
                                    </div>
                                </TabPanel>
                            </Tabs>

                        </div>

                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                            <button type="button" onClick={onSave} className="btn btn-success">{role && role.id ? "Save" : "Add"}</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Roles;