import { React } from 'react';
import { BrowserRouter,Routes,Route } from "react-router-dom";
import { ContextProvider } from "./Contexts/ContextProvider";

import DashboardLayout from "./Dashboard/Layout/Master";
import PageNotFoundPage from "./Dashboard/Pages/PageNotFound";
import ProfilePage from "./Dashboard/Pages/Profile";

import DashboardPage from "./Dashboard/Pages/Dashboard";
import CountriesPage from "./Dashboard/Pages/Countries";
import RolesPage from "./Dashboard/Pages/Roles";

import UsersPage from "./Dashboard/Pages/Users";
import CompaniesPage from "./Dashboard/Pages/Companies";
import PrintersPage from "./Dashboard/Pages/Printers";
import EmployeesPage from "./Dashboard/Pages/Employees";
import FoodCategoriesPage from "./Dashboard/Pages/FoodCategories";
import FoodPage from "./Dashboard/Pages/Food";

import GuestLayout from "./Guest/Layout/master";
import LoginPage from "./Guest/Pages/Login";
import ForgotPasswordPage from "./Guest/Pages/ForgotPassword";

function App() {
  return (
    <ContextProvider>
      <BrowserRouter>
        <Routes>
        <Route path="*" element={<PageNotFoundPage />} />

          <Route element={<DashboardLayout />}>
            <Route path="/profile" element={<ProfilePage />} />
            {/* <Route path="/" element={<Navigate replace to="/dashboard" />} /> */}
            <Route path="/" element={<DashboardPage />} />
            <Route path="/dashboard/countries" element={<CountriesPage />} />
            <Route path="/dashboard/roles" element={<RolesPage />} />
            <Route path="/dashboard/users" element={<UsersPage />} />
            <Route path="/dashboard/companies" element={<CompaniesPage />} />
            <Route path="/dashboard/printers" element={<PrintersPage />} />
            <Route path="/dashboard/employees" element={<EmployeesPage />} />
            <Route path="/dashboard/food-categories" element={<FoodCategoriesPage />} />
            <Route path="/dashboard/food" element={<FoodPage />} />
            
          </Route>

          <Route element={<GuestLayout />}>
            <Route path="/login" element={<LoginPage />} />
            <Route path="/forgot-password" element={<ForgotPasswordPage />} />
          </Route>
        </Routes>
      </BrowserRouter>
    </ContextProvider>

  );
}

export default App;

