import { useLayoutEffect, useRef, useState } from "react";
import axiosClient from "../../Helpers/Axios-Client";
import DataTable from 'react-data-table-component';
import Swal from 'sweetalert2'
import { toast } from 'react-toastify';
import { Link } from "react-router-dom";
import { useMainContext } from "../../Contexts/ContextProvider";
import 'react-tabs/style/react-tabs.css';

function Users() {

    const { user } = useMainContext();

    const emptyRow = {
        id: null,
        is_active: 1,
        role: null,
        img: null,
        name: "",
        mobile: "",
        email: "",
        password: "",
        lang: 0,
        company: null,
        country: null
    };

    const [companies, setCompanies] = useState();
    const [countries, setCountries] = useState();
    const [roles, setRoles] = useState();

    const [users, setUsers] = useState();
    const [cuser, setCUser] = useState(emptyRow);

    const openDetailsModal_Ref = useRef();
    const closeDetailsModal_Ref = useRef();

    const [tempImage, setTempImage] = useState(null);
    const btnImageSelect_Ref = useRef();
    const imgPreview_Ref = useRef();

    const columns = [
        {
            name: '#',
            selector: row => row.id,
            sortable: true,
            center: true,
            width: "80px",
        },
        {
            name: 'Active',
            selector: row => row.is_active,
            sortable: true,
            center: true,
            width: "100px",
            cell: row => (row.is_active) ? <span className="badge badge-success" >Active</span> : <span className="badge badge-danger" >Inactive</span>,

        },
        {
            name: 'Image',
            selector: row => row.img,
            sortable: true,
            width: "100px",
            cell: row => <img src={row.img ? row.img : "/img/Image_not_available.png"} className="img-circle m-2 elevation-2" width="50" height="50" alt="User Logo" />

        },

        {
            name: 'Role',
            selector: row => row.role.en_name,
            sortable: true,
            center: true,
            width: "130px",
        },
        {
            name: 'Name',
            selector: row => row.name,
            sortable: true,
            width: "150px",

        },
        {
            name: 'EMail',
            selector: row => row.email,
            sortable: true,
            center: true,
        },
        {
            name: 'Created at',
            selector: row => row.created_at,
            sortable: true,
            center: true,
            width: "160px",

        },
        {
            name: '',
            selector: row => row.actions,
            center: true,
            width: "300px",
            cell: row => <>
                {user.role && user.role.users_edit ? <button onClick={ev => onView(row)} type="button" className="btn btn-primary btn-sm text-white mr-2" ><i className="fa fa-edit" /> Edit</button> : "" }
                {user.role && user.role.users_delete ? <button onClick={ev => onDelete(row)} type="button" className="btn btn-danger btn-sm text-white mr-2"><i className="fa fa-trash-alt" /> Delete</button> : ""}
            </>,
        },
    ];

    useLayoutEffect(() => {
        getUsers();
        getRoles();
        getCountries();
        getCompanies();
    }, []);

    const getUsers = () => {

        axiosClient.get("/users").then((response) => {
            process.env.NODE_ENV === "development" && console.log("Init Users Data", response.data);
            setUsers(response.data);

        }).catch((error) => {
            process.env.NODE_ENV === "development" && console.log("Init Users Error:", error);
        });

    }

    const getRoles = () => {

        axiosClient.get("/roles").then((response) => {
            process.env.NODE_ENV === "development" && console.log("Init Roles Data", response.data);
            setRoles(response.data);

        }).catch((error) => {
            process.env.NODE_ENV === "development" && console.log("Init Roles Error:", error);
        });

    }

    const getCountries = () => {

        axiosClient.get("/countries").then((response) => {
            process.env.NODE_ENV === "development" && console.log("Init Countries Data", response.data);
            setCountries(response.data);

        }).catch((error) => {

            process.env.NODE_ENV === "development" && console.log("Init Countries Error:", error);
        });

    }

    const getCompanies = () => {

        axiosClient.get("/companies").then((response) => {
            process.env.NODE_ENV === "development" && console.log("Init Companies Data", response.data);
            setCompanies(response.data);


        }).catch((error) => {

            process.env.NODE_ENV === "development" && console.log("Init Companies Error:", error);

        });

    }

    const onView = (selectedRow) => {

        if (selectedRow) {
            setCUser(selectedRow);
        }
        else {
            setCUser(emptyRow);
        }

        openDetailsModal_Ref.current.click();

    }

    const onSave = () => {

        if (cuser.id) {
            process.env.NODE_ENV === "development" && console.log("Edit User", cuser);

            const formData = new FormData();
            formData.append("_method", "PUT");
            formData.append("id", cuser.id);
            formData.append("is_active", cuser.is_active);
            formData.append("role[id]", cuser.role.id);
            tempImage && formData.append("img", tempImage);
            formData.append("name", cuser.name);
            formData.append("mobile", cuser.mobile);
            formData.append("email", cuser.email);
            cuser.password && formData.append("password", cuser.password);
            formData.append("lang", cuser.lang);


            if (cuser.role && (cuser.role.id === 4 || cuser.role.id === 5)) 
            {
                formData.append("company[id]", cuser.company != null ? cuser.company.id : null);
            }
            else if (cuser.role && cuser.role.id === 3) 
            {
                formData.append("country[id]", cuser.country != null ? cuser.country.id : null);
            }
            else 
            {
                formData.append("company", null);
                formData.append("country", null);
            }

            axiosClient.post(`/users/${cuser.id}`, formData, { headers: { "Content-Type": "multipart/form-data" }, }).then((response) => {

                process.env.NODE_ENV === "development" && console.log("Edit User Response : ", response)

                setUsers(response.data);
                closeDetailsModal_Ref.current.click();


                Swal.fire({
                    position: 'center',
                    icon: 'success',
                    title: `${cuser.name} has been updated`,
                    showConfirmButton: false,
                    timer: 1000
                });


            }).catch((error) => {

                if (error.response && error.response.status === 422) {

                    error.response.data.errors && Object.keys(error.response.data.errors).map(key => (toast.error(error.response.data.errors[key][0])));
                }
                else {
                    process.env.NODE_ENV === "development" && console.log("Edit User Error:", error.response);
                }

            });
        }
        else {
            process.env.NODE_ENV === "development" && console.log("Add", cuser);

            const formData = new FormData();
            formData.append("id", cuser.id);
            formData.append("is_active", cuser.is_active);
            formData.append("role[id]", cuser.role && cuser.role.id);
            tempImage && formData.append("img", tempImage);
            formData.append("name", cuser.name);
            formData.append("mobile", cuser.mobile);
            formData.append("email", cuser.email);
            formData.append("password", cuser.password);
            formData.append("lang", cuser.lang);

            if (cuser.role && (cuser.role.id === 4 || cuser.role.id === 5)) {
                formData.append("company[id]", cuser.company != null ? cuser.company.id : null);
            }
            else if (cuser.role && cuser.role.id === 3) {
                formData.append("country[id]", cuser.country != null ? cuser.country.id : null);
            }
            else {
                formData.append("company", null);
                formData.append("country", null);
            }

            axiosClient.post(`/users`, formData, { headers: { "Content-Type": "multipart/form-data" } }).then((response) => {

                process.env.NODE_ENV === "development" && console.log("Add User Response : ", response);

                setUsers(response.data);

                closeDetailsModal_Ref.current.click();

                Swal.fire({
                    position: 'center',
                    icon: 'success',
                    title: `${cuser.name} has been added`,
                    showConfirmButton: false,
                    timer: 1000
                });

            }).catch((error) => {

                if (error.response && error.response.status === 422) {


                    error.response.data.errors && Object.keys(error.response.data.errors).map(key => (
                        toast.error(error.response.data.errors[key][0])
                    ));
                }
                else {
                    process.env.NODE_ENV === "development" && console.log("Add User Error :", error.response);
                }

            });
        }

    }

    const onDelete = (selectedRow) => {

        process.env.NODE_ENV === "development" && console.log("Delete User :", selectedRow);

        Swal.fire({
            title: `Are you sure you want to delete ${selectedRow.name} ?`,
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#d33',
            cancelButtonColor: '#28a745',
            confirmButtonText: 'Yes, delete it!',
            reverseButtons: true
        }).then((result) => {

            if (result.isConfirmed) {

                axiosClient.delete(`/users/${selectedRow.id}`).then((response) => {

                    process.env.NODE_ENV === "development" && console.log("Delete User Response:", response);
                    setUsers(response.data);


                    Swal.fire({
                        position: 'center',
                        icon: 'success',
                        title: `${cuser.en_name} has been deleted`,
                        showConfirmButton: false,
                        timer: 1000
                    });

                }).catch((error) => {

                    process.env.NODE_ENV === "development" && console.log("Delete User Error :",error);
                });

            }
        })

    }

    const onImageSelect = (ev) => {

        btnImageSelect_Ref.current.click();
    }

    const onImageSelected = (ev) => {

        if (ev.target.files.length > 0) {
            setCUser({ ...cuser, img: URL.createObjectURL(ev.target.files[0]) });
            setTempImage(ev.target.files[0]);
        }

    }

    return (
        <div className="content-wrapper">

            <div className="content-header">
                <div className="container-fluid">
                    <div className="row mb-2">
                        <div className="col-sm-6">
                            <h1 className="m-0">Users</h1>
                        </div>

                        <div className="col-sm-6">
                            <ol className="breadcrumb float-sm-right">
                                <li className="breadcrumb-item"><Link to={"/"} className="text-success">Dashboard</Link></li>
                                <li className="breadcrumb-item active">Users</li>
                            </ol>
                        </div>

                    </div>

                </div>

            </div>

            <div className="content">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12">
                            <div className="card">
                                <div className="card-header text-right">
                                    {user.role && user.role.users_add ? <button onClick={ev => onView(null)} type="button" className="btn btn-success btn-lg" ><i className="fa fa-plus-circle" /> New User</button> : "" }
                                </div>

                                <div className="card-body">
                                    <div className="row ">
                                        <div className="form-group offset-sm-10 col-sm-2 ">
                                            <div className="input-group">
                                                <input type="search" className="form-control " placeholder="Search" />
                                                <div className="input-group-append">
                                                    <button className="btn btn-default">
                                                        <i className="fa fa-search" />
                                                    </button>
                                                </div>
                                            </div>

                                        </div>

                                    </div>

                                    <DataTable
                                        className="table table-bordered"
                                        columns={columns}
                                        data={users}
                                        pagination
                                    />

                                </div>
                            </div>

                        </div>

                    </div>
                </div>
            </div>

            <button style={{ display: "none" }} ref={openDetailsModal_Ref} type="button" className="btn btn-success btn-lg" data-toggle="modal" data-target="#DetailsModal"><i className="fa fa-plus-circle" /> </button>
            <div className="modal fade" id="DetailsModal" data-backdrop='static' tabIndex={-1} role="dialog" aria-labelledby="DetailsModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered" role="document">
                    <div className="modal-content">

                        <div className="modal-header">
                            <h5 className="modal-title text-center" id="exampleModalLabel">{cuser && cuser.id ? "Edit User" : "Add User"}</h5>
                            <button ref={closeDetailsModal_Ref} type="button" className="close" data-dismiss="modal" aria-label="Close" >
                                <span aria-hidden="true">×</span>
                            </button>
                        </div>

                        <div className="modal-body">
                        <div className="row">
                                        <div className="col-sm-8">
                                            <form className="form-horizontal">
                                                {/* Is Active */}
                                                <div className="form-group row">
                                                    <div className="offset-sm-4 col-sm-9">
                                                        <div className="form-check">

                                                            <input className="form-check-input"
                                                                checked={cuser.is_active}
                                                                onChange={ev => setCUser({ ...cuser, is_active: Number(ev.target.checked) })}
                                                                type="checkbox"
                                                                id="ckActive" />

                                                            <label className="form-check-label" htmlFor="ckActive">Active</label>
                                                        </div>
                                                    </div>
                                                </div>

                                                {/* User Role */}
                                                <div className="form-group row">
                                                    <label htmlFor="sctRoles" className="col-sm-4 col-form-label">Role</label>
                                                    <div className="col-sm-8">
                                                    <select id="sctRoles"
                                                            value={cuser.role != null ? cuser.role.id : 0}
                                                            onChange={ev => setCUser({ ...cuser, role: roles.find(o => o.id === Number(ev.target.value)) })}
                                                            className="form-control form-select form-select-lg"
                                                            aria-label="Default select example">
                                                            <option value={0}>Select Role</option>
                                                            {roles && roles.map(roleRow => <option key={roleRow.id} value={roleRow.id}>{roleRow.en_name}</option>)}
                                                        </select>
                                                    </div>
                                                </div>

                                                {/* Name */}
                                                <div className="form-group row">
                                                    <label htmlFor="txtName" className="col-sm-4 col-form-label">Name</label>
                                                    <div className="col-sm-8">

                                                        <input type="text"
                                                            value={cuser.name}
                                                            onChange={ev => setCUser({ ...cuser, name: ev.target.value })}
                                                            className="form-control"
                                                            id="txtName"
                                                            placeholder="Full Name" />
                                                    </div>
                                                </div>

                                                {/* EMail */}
                                                <div className="form-group row">
                                                    <label htmlFor="txtEMail" className="col-sm-4 col-form-label">EMail</label>
                                                    <div className="col-sm-8">
                                                        <input type="email"
                                                            value={cuser.email}
                                                            onChange={ev => setCUser({ ...cuser, email: ev.target.value })}
                                                            className="form-control"
                                                            id="txtEMail"
                                                            placeholder="EMail" />
                                                    </div>
                                                </div>

                                                {/* Mobile */}
                                                <div className="form-group row">
                                                    <label htmlFor="txtMobile" className="col-sm-4 col-form-label">Mobile</label>
                                                    <div className="col-sm-8">
                                                        <input type="phone"
                                                            value={cuser.mobile}
                                                            onChange={ev => setCUser({ ...cuser, mobile: ev.target.value })}
                                                            className="form-control"
                                                            id="txtMobile"
                                                            placeholder="Mobile" />
                                                    </div>
                                                </div>

                                                {/* Password */}
                                                <div className="form-group row">
                                                    <label htmlFor="txtPassword" className="col-sm-4 col-form-label">Password</label>
                                                    <div className="col-sm-8">
                                                        <input type="password"
                                                            value={cuser.password}
                                                            onChange={ev => setCUser({ ...cuser, password: ev.target.value })}
                                                            className="form-control"
                                                            id="txtPassword"
                                                            placeholder="Password"
                                                            autoComplete="true"
                                                        />
                                                    </div>
                                                </div>

                                                {/* Select Default Language */}
                                                <div className="form-group row">
                                                    <label htmlFor="sctDefaultLang" className="col-sm-4 col-form-label">Language</label>
                                                    <div className="col-sm-8">

                                                        <select id="sctDefaultLang"
                                                            value={cuser.lang}
                                                            onChange={ev => setCUser({ ...cuser, lang: Number(ev.target.value) })}
                                                            className="form-control form-select form-select-lg"
                                                            aria-label="Default select example">
                                                            <option value={0}>English</option>
                                                            <option value={1}>Arabic</option>
                                                        </select>
                                                    </div>
                                                </div>

                                                {/*  Company */}
                                                {cuser.role && (cuser.role.id === 4 || cuser.role.id === 5) && <div className="form-group row">
                                                    <label htmlFor="sctCompanies" className="col-sm-4 col-form-label">Company</label>
                                                    <div className="col-sm-8">

                                                        <select id="sctCompanies"
                                                            value={cuser.company != null ? cuser.company.id : 0}
                                                            onChange={ev => setCUser({ ...cuser, company: companies.find(o => o.id === Number(ev.target.value)) })}
                                                            className="form-control form-select form-select-lg"
                                                            aria-label="Default select example">
                                                            <option value={0}>Select Company</option>
                                                            {companies && companies.map(companyRow => <option key={companyRow.id} value={companyRow.id}>{companyRow.en_name}</option>)}
                                                        </select>
                                                    </div>
                                                </div>}

                                                {/*  Country */}
                                                {(cuser.role && cuser.role.id === 3) && <div className="form-group row">
                                                    <label htmlFor="sctCountries" className="col-sm-4 col-form-label">Country</label>
                                                    <div className="col-sm-8">

                                                        <select id="sctCountries"
                                                            value={cuser.country != null ? cuser.country.id : 0}
                                                            onChange={ev => setCUser({ ...cuser, country: countries.find(o => o.id === Number(ev.target.value)) })}
                                                            className="form-control form-select form-select-lg"
                                                            aria-label="Default select example">
                                                            <option value={0}>Select Country</option>
                                                            {countries && countries.map(countryRow => <option key={countryRow.id} value={countryRow.id}>{countryRow.en_name}</option>)}
                                                        </select>
                                                    </div>
                                                </div>}
                                            </form>
                                        </div>

                                        <div className="col-sm-4">
                                            <h6 className="col-sm-12 text-center">User Image</h6>
                                            <img ref={imgPreview_Ref}
                                                className="col-sm-12 img-fluid img-thumbnail"
                                                src={cuser.img != null ? cuser.img : "/img/Image_not_available.png"}
                                                alt="UserImage"
                                            />
                                            <button type="button" onClick={onImageSelect} className="btn btn-success col-sm-12 mt-2">{cuser && cuser.img ? "Change" : "Select"}</button>


                                            <input
                                                ref={btnImageSelect_Ref}
                                                style={{ display: "none" }}
                                                type="file"
                                                onChange={onImageSelected}
                                            />
                                        </div>
                                    </div>
                        </div>

                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                            <button type="button" onClick={onSave} className="btn btn-success">{cuser && cuser.id ? "Save" : "Add"}</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Users;