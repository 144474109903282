import { useLayoutEffect, useRef, useState } from "react";
import axiosClient from "../../Helpers/Axios-Client";
import DataTable from 'react-data-table-component';
import Swal from 'sweetalert2'
import { toast } from 'react-toastify';
import { Link } from "react-router-dom";
import { useMainContext } from "../../Contexts/ContextProvider";

function Printers() {
    const { user } = useMainContext();

    const initRowValues = {
        id: null,
        is_active: true,
        ar_name: "",
        en_name: "",
        serial_number: "",
        company: null,
    };

    const [companies, setCompanies] = useState();
    const [printers, setPrinters] = useState();

    const [printer, setPrinter] = useState(initRowValues);

    const openDetailsModal_Ref = useRef();
    const closeDetailsModal_Ref = useRef();

    const columns = [
        {
            name: '#',
            selector: row => row.id,
            sortable: true,
            center: true,
            width: "80px",
        },
        {
            name: 'Active',
            selector: row => row.is_active,
            sortable: true,
            center: true,
            cell: row => (row.is_active) ? <span className="badge badge-success" >Active</span> : <span className="badge badge-danger" >Inactive</span>,
        },
        {
            name: 'Name',
            selector: row => row.en_name,
            sortable: true,
        },
        {
            name: 'Company',
            selector: row => row.company,
            sortable: true,
            cell: row => <div>
                <img src={row.company.logo ? row.company.logo : "/img/Image_not_available.png"} className="img-fluid m-2 elevation-2"  width="40" height="40"  alt="Company Logo" />
                <span>{row.company.en_name}</span>
            </div>
        },
        {
            name: 'Created at',
            selector: row => row.created_at,
            sortable: true,
            center: true,
        },
        {
            name: '',
            selector: row => row.actions,
            center: true,
            width: "300px",
            cell: row => <>
                {user.role && user.role.printers_edit ? <button onClick={ev => onView(row)} type="button" className="btn btn-primary btn-sm text-white mr-2" ><i className="fa fa-edit" /> Edit</button> : ""}
                {user.role && user.role.printers_delete ? <button onClick={ev => onDelete(row)} type="button" className="btn btn-danger btn-sm text-white mr-2"><i className="fa fa-trash-alt" /> Delete</button> : "" }
            </>,
        },
    ];

    useLayoutEffect(() => {
        getCompanies();
        getPrinters();
    }, []);

    const getCompanies = () => {

        axiosClient.get("/companies").then((response) => {

            process.env.NODE_ENV === "development" && console.log("companies", response.data);

            setCompanies(response.data);


        }).catch((error) => {

            console.log(error);

        });

    }

    const getPrinters = () => {

        axiosClient.get("/printers").then((response) => {
            process.env.NODE_ENV === "development" && console.log("printers", response.data);

            setPrinters(response.data);

        }).catch((error) => {

            console.log(error);
        });
    }

    const onView = (selectedRow) => {
        if (selectedRow) {
            setPrinter(selectedRow);
        }
        else {
            setPrinter(initRowValues);
        }

        openDetailsModal_Ref.current.click();
    }

    const onSave = () => {

        if (printer.id) {
            process.env.NODE_ENV === "development" && console.log("Edit Printer:", printer);

            axiosClient.put(`/printers/${printer.id}`, printer).then((response) => {

                process.env.NODE_ENV === "development" && console.log("Response : ", response);

                getPrinters();
                closeDetailsModal_Ref.current.click();

                Swal.fire({
                    position: 'center',
                    icon: 'success',
                    title: `${printer.en_name} has been updated`,
                    showConfirmButton: false,
                    timer: 1000
                });


            }).catch((error) => {

                const response = error.response;

                if (response && response.status === 422) {

                    if (response.data.errors) {
                        Object.keys(response.data.errors).map(key => (toast.error(response.data.errors[key][0])));
                    }

                }
                else {
                    console.log(response);
                }

            });
        }
        else {
            console.log("Add Printer:", printer);

            axiosClient.post(`/printers`, printer).then((response) => {
                process.env.NODE_ENV === "development" && console.log("Response : ", response);
                getPrinters();
                closeDetailsModal_Ref.current.click();

                Swal.fire({
                    position: 'center',
                    icon: 'success',
                    title: `${printer.en_name} has been added`,
                    showConfirmButton: false,
                    timer: 1000
                });

            }).catch((error) => {

                const response = error.response;

                process.env.NODE_ENV === "development" && console.log(response);

                if (response && response.status === 422) {
                    if (response.data.errors) {
                        Object.keys(response.data.errors).map(key => (toast.error(response.data.errors[key][0])));
                    }
                }
                else {
                    console.log(response);
                }
            });
        }

    }

    const onDelete = (selectedRow) => {

        Swal.fire({
            title: `Are you sure you want to delete ${selectedRow.en_name} ?`,
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#d33',
            cancelButtonColor: '#28a745',
            confirmButtonText: 'Yes, delete it!',
            reverseButtons: true
        }).then((result) => {

            if (result.isConfirmed) {

                axiosClient.delete(`/printers/${selectedRow.id}`).then((response) => {

                    process.env.NODE_ENV === "development" && console.log(response);
                    getPrinters();

                    Swal.fire({
                        position: 'center',
                        icon: 'success',
                        title: `${printer.en_name} has been deleted`,
                        showConfirmButton: false,
                        timer: 1000
                    });

                }).catch((error) => {

                    console.log(error);
                });

            }
        })

    }


    return (
        <div className="content-wrapper">

            <div className="content-header">
                <div className="container-fluid">
                    <div className="row mb-2">
                        <div className="col-sm-6">
                            <h1 className="m-0">Printers</h1>
                        </div>

                        <div className="col-sm-6">
                            <ol className="breadcrumb float-sm-right">
                                <li className="breadcrumb-item"><Link to={"/"} className="text-success">Dashboard</Link></li>
                                <li className="breadcrumb-item active">Printers</li>
                            </ol>

                        </div>

                    </div>

                </div>

            </div>

            <div className="content">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12">
                            <div className="card">
                                <div className="card-header text-right">
                                    {user.role && user.role.printers_add && <button onClick={ev => onView(null)} type="button" className="btn btn-success btn-lg" ><i className="fa fa-plus-circle" /> New Printer</button>}
                                </div>

                                <div className="card-body">
                                    <div className="row ">
                                        <div className="form-group offset-sm-10 col-sm-2 ">
                                            <div className="input-group">
                                                <input type="search" className="form-control " placeholder="Search" />
                                                <div className="input-group-append">
                                                    <button className="btn btn-default">
                                                        <i className="fa fa-search" />
                                                    </button>
                                                </div>
                                            </div>

                                        </div>

                                    </div>

                                    <DataTable className="table table-bordered" columns={columns} data={printers} pagination />

                                </div>
                            </div>

                        </div>

                    </div>
                </div>
            </div>


            <button style={{ display: "none" }} ref={openDetailsModal_Ref} type="button" className="btn btn-success btn-lg" data-toggle="modal" data-target="#DetailsModal"><i className="fa fa-plus-circle" /> </button>
            <div className="modal fade" id="DetailsModal" data-backdrop='static' tabIndex={-1} role="dialog" aria-labelledby="DetailsModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered" role="document">
                    <div className="modal-content">

                        <div className="modal-header">
                            <h5 className="modal-title text-center" id="exampleModalLabel">{printer && printer.id ? "Edit Printer" : "Add Printer"}</h5>
                            <button ref={closeDetailsModal_Ref} type="button" className="close" data-dismiss="modal" aria-label="Close" >
                                <span aria-hidden="true">×</span>
                            </button>
                        </div>

                        <div className="modal-body">
                            <form className="form-horizontal">
                                <div className="form-group row">
                                    <div className="offset-sm-4 col-sm-9">
                                        <div className="form-check">

                                            <input className="form-check-input "
                                                checked={printer.is_active}
                                                onChange={ev => setPrinter({ ...printer, is_active: Number(ev.target.checked) })}
                                                type="checkbox"
                                                id="ckActive" />

                                            <label className="form-check-label" htmlFor="ckActive">Active</label>
                                        </div>
                                    </div>
                                </div>

                                <div className="form-group row">
                                    <label htmlFor="txtArName" className="col-sm-4 col-form-label">Name ( Arabic )</label>
                                    <div className="col-sm-8">

                                        <input type="text"
                                            value={printer.ar_name}
                                            onChange={ev => setPrinter({ ...printer, ar_name: ev.target.value })}
                                            className="form-control"
                                            id="txtArName"
                                            placeholder="Arabic Name" />
                                    </div>
                                </div>

                                <div className="form-group row">
                                    <label htmlFor="txtEnName" className="col-sm-4 col-form-label">Name ( English )</label>
                                    <div className="col-sm-8">
                                        <input type="text"
                                            value={printer.en_name}
                                            onChange={ev => setPrinter({ ...printer, en_name: ev.target.value })}
                                            className="form-control"
                                            id="txtEnName"
                                            placeholder="English Name" />
                                    </div>
                                </div>

                             <div className="form-group row">
                                    <label htmlFor="txtSerialNumber" className="col-sm-4 col-form-label">Serial Number</label>
                                    <div className="col-sm-8">
                                        <input type="text"
                                            disabled={false}
                                            value={printer.serial_number}
                                            onChange={ev => setPrinter({ ...printer, serial_number: ev.target.value })}
                                            className="form-control"
                                            id="txtSerialNumber"
                                            placeholder="Serial Number" />
                                    </div>
                                </div>
                                

                                <div className="form-group row">
                                    <label htmlFor="sctCompanies" className="col-sm-4 col-form-label">Company</label>
                                    <div className="col-sm-6">

                                        <select id="sctCompanies"
                                            value={printer.company ? printer.company.id : 0}
                                            onChange={ev => setPrinter({ ...printer, company: companies.find(o => o.id === Number(ev.target.value)) })}
                                            className="form-control form-select form-select-lg"
                                            aria-label="Default select example">
                                            <option value={0}>Select Company</option>
                                            {companies && companies.map(companyRow => <option key={companyRow.id} value={companyRow.id}>{companyRow.en_name}</option>)}
                                        </select>
                                    </div>
                                </div>


                            </form>
                        </div>

                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                            <button type="button" onClick={onSave} className="btn btn-success">{printer && printer.id ? "Save" : "Add"}</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    );
}

export default Printers;