import { useLayoutEffect, useRef, useState } from "react";
import axiosClient from "../../Helpers/Axios-Client";
import DataTable from 'react-data-table-component';
import Swal from 'sweetalert2'
import { toast } from 'react-toastify';
import { Link } from "react-router-dom";
import { useMainContext } from "../../Contexts/ContextProvider";

function FoodCategories() {
    const { user } = useMainContext();

    const initRowValue = {
        id: null,
        is_active: 1,
        img: null,
        ar_name: "",
        en_name: "",
        company: null,
    };

    const [foodCategories, setFoodCategories] = useState();
    const [companies, setCompanies] = useState();
    const [foodCategory, setFoodCategory] = useState(initRowValue);

    const openDetailsModal_Ref = useRef();
    const closeDetailsModal_Ref = useRef();

    const [tempImage, setTempImage] = useState(null);
    const btnImageSelect_Ref = useRef();
    const imgPreview_Ref = useRef();

    const columns = [
        {
            name: '#',
            selector: row => row.id,
            sortable: true,
            center: true,
            width: "80px",
        },
        {
            name: 'Active',
            selector: row => row.is_active,
            sortable: true,
            center: true,
            cell: row => (row.is_active) ? <span className="badge badge-success" >Active</span> : <span className="badge badge-danger" >Inactive</span>,

        },
        {
            name: 'Image',
            selector: row => row.img,
            sortable: true,
            center: true,
            cell: row => <img src={row.img ? row.img : "/img/Image_not_available.png"} className="img-fluid m-2 elevation-2" width="50" height="45" alt="Food Category" />

        },

        {
            name: 'Name',
            selector: row => row.en_name,
            sortable: true,

        },
        {
            name: 'Company',
            selector: row => row.company,
            sortable: true,
            cell: row => <div>
                <img src={row.company.logo ? row.company.logo : "/img/Image_not_available.png"} className="img-fluid m-2 elevation-2" width="40" height="40" alt="Company Logo" />
                <span>{row.company.en_name}</span>
            </div>
        },
        {
            name: 'Created at',
            selector: row => row.created_at,
            sortable: true,
            center: true,
        },
        {
            name: '',
            selector: row => row.actions,
            center: true,
            width: "300px",
            cell: row => <>
                {user.role && user.role.food_categories_edit ? <button onClick={ev => onView(row)} type="button" className="btn btn-primary btn-sm text-white mr-2" ><i className="fa fa-edit" /> Edit</button> : "" }
                {user.role && user.role.food_categories_delete ? <button onClick={ev => onDelete(row)} type="button" className="btn btn-danger btn-sm text-white mr-2"><i className="fa fa-trash-alt" /> Delete</button> : "" }
            </>,
        },
    ];

    useLayoutEffect(() => {

        getFoodCategories();
        getCompanies();

    }, []);

    const getFoodCategories = () => {

        axiosClient.get("/food-categories").then((response) => {

            process.env.NODE_ENV === "development" && console.log("Food Categories", response.data);
            setFoodCategories(response.data);

        }).catch((error) => {
            process.env.NODE_ENV === "development" && console.log(error);
        });

    }

    const getCompanies = () => {

        axiosClient.get("/companies").then((response) => {
            process.env.NODE_ENV === "development" && console.log("Companies", response.data);
            setCompanies(response.data);

        }).catch((error) => {
            process.env.NODE_ENV === "development" && console.log(error);
        });

    }

    const onView = (selectedRow) => {
        setTempImage(null);

        if (selectedRow) {
            setFoodCategory(selectedRow);
        }
        else {
            setFoodCategory(initRowValue);
        }

        openDetailsModal_Ref.current.click();
    }

    const onSave = (ev) => {

        if (foodCategory.id) {

            const formData = new FormData();
            formData.append("id", foodCategory.id);
            formData.append("_method", "PUT");
            formData.append("is_active", foodCategory.is_active);
            tempImage && formData.append("img", tempImage);
            formData.append("ar_name", foodCategory.ar_name);
            formData.append("en_name", foodCategory.en_name);
            formData.append("company[id]", foodCategory.company != null ? foodCategory.company.id : null);


            axiosClient.post(`/food-categories/${foodCategory.id}`, formData, { "Content-Type": "multipart/form-data" }).then((response) => {

                process.env.NODE_ENV === "development" && console.log("Response : ", response);

                setFoodCategories(response.data);
                closeDetailsModal_Ref.current.click();

                Swal.fire({
                    position: 'center',
                    icon: 'success',
                    title: `${foodCategory.en_name} has been updated`,
                    showConfirmButton: false,
                    timer: 1000
                });


            }).catch((error) => {

                if (error.response && error.response.status === 422) {

                    error.response.data.errors && Object.keys(error.response.data.errors).map(key => (
                        toast.error(error.response.data.errors[key][0])
                    ));

                }
                else {
                    process.env.NODE_ENV === "development" && console.log(error.response);
                }

            });
        }
        else {

            const formData = new FormData();
            formData.append("id", foodCategory.id);
            formData.append("is_active", foodCategory.is_active);
            tempImage && formData.append("img", tempImage);
            formData.append("ar_name", foodCategory.ar_name);
            formData.append("en_name", foodCategory.en_name);
            formData.append("company[id]", foodCategory.company != null ? foodCategory.company.id : null);

            axiosClient.post(`/food-categories`, formData, { headers: { "Content-Type": "multipart/form-data" } }).then((response) => {

                process.env.NODE_ENV === "development" && console.log("Response : ", response);
                setFoodCategories(response.data);

                closeDetailsModal_Ref.current.click();

                Swal.fire({
                    position: 'center',
                    icon: 'success',
                    title: `${foodCategory.en_name} has been added`,
                    showConfirmButton: false,
                    timer: 1000
                });

            }).catch((error) => {

                if (error.response && error.response.status === 422) {

                    error.response.data.errors && Object.keys(error.response.data.errors).map(key => (
                        toast.error(error.response.data.errors[key][0])
                    ));
                }
                else {
                    process.env.NODE_ENV === "development" && console.log(error.response);
                }


            });
        }

    }

    const onDelete = (selectedRow) => {

        Swal.fire({
            title: `Are you sure you want to delete ${selectedRow.en_name} ?`,
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#d33',
            cancelButtonColor: '#28a745',
            confirmButtonText: 'Yes, delete it!',
            reverseButtons: true
        }).then((result) => {

            if (result.isConfirmed) {

                axiosClient.delete(`/food-categories/${selectedRow.id}`).then((response) => {
                  process.env.NODE_ENV  === "development" && console.log(response);
                    setFoodCategories(response.data);

                    Swal.fire({
                        position: 'center',
                        icon: 'success',
                        title: `${foodCategory.en_name} has been deleted`,
                        showConfirmButton: false,
                        timer: 1000
                    });

                }).catch((error) => {

                    process.env.NODE_ENV  === "development" &&  console.log(error);
                });

            }
        })

    }

    const onImageSelect = (ev) => {

        btnImageSelect_Ref.current.click();
    }

    const onImageSelected = (ev) => {


        if (ev.target.files.length > 0) {
            setFoodCategory({ ...foodCategory, img: URL.createObjectURL(ev.target.files[0]) });
            setTempImage(ev.target.files[0]);
        }

    }

    return (

        <div className="content-wrapper">

            <div className="content-header">
                <div className="container-fluid">
                    <div className="row mb-2">
                        <div className="col-sm-6">
                            <h1 className="m-0">Food Categories</h1>
                        </div>

                        <div className="col-sm-6">
                            <ol className="breadcrumb float-sm-right">
                                <li className="breadcrumb-item"><Link to={"/"} className="text-success">Dashboard</Link></li>
                                <li className="breadcrumb-item active">Food Categories</li>
                            </ol>
                        </div>

                    </div>

                </div>

            </div>

            <div className="content">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12">
                            <div className="card">
                                <div className="card-header text-right">
                                    {user.role && user.role.food_categories_add ? <button onClick={ev => onView(null)} type="button" className="btn btn-success btn-lg" ><i className="fa fa-plus-circle" /> New Food Category</button> : "" }
                                </div>

                                <div className="card-body">
                                    <div className="row ">
                                        <div className="form-group offset-sm-10 col-sm-2 ">
                                            <div className="input-group">
                                                <input type="search" className="form-control " placeholder="Search" />
                                                <div className="input-group-append">
                                                    <button className="btn btn-default">
                                                        <i className="fa fa-search" />
                                                    </button>
                                                </div>
                                            </div>

                                        </div>

                                    </div>

                                    <DataTable className="table table-bordered" columns={columns} data={foodCategories} pagination />

                                </div>
                            </div>

                        </div>

                    </div>
                </div>
            </div>


            <button style={{ display: "none" }} ref={openDetailsModal_Ref} type="button" className="btn btn-success btn-lg" data-toggle="modal" data-target="#DetailsModal"><i className="fa fa-plus-circle" /> </button>
            <div className="modal fade" id="DetailsModal" data-backdrop='static' tabIndex={-1} role="dialog" aria-labelledby="DetailsModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered" role="document">
                    <div className="modal-content">

                        <div className="modal-header">
                            <h5 className="modal-title text-center" id="exampleModalLabel">{foodCategory && foodCategory.id ? "Edit Food Categories" : "Add Food Categories"}</h5>
                            <button ref={closeDetailsModal_Ref} type="button" className="close" data-dismiss="modal" aria-label="Close" >
                                <span aria-hidden="true">×</span>
                            </button>
                        </div>

                        <div className="modal-body">
                            <div className="row">

                                <div className="col-sm-8">
                                    <form className="form-horizontal">
                                        <div className="form-group row">
                                            <div className="offset-sm-5 col-sm-9">
                                                <div className="form-check">

                                                    <input className="form-check-input"
                                                        checked={foodCategory.is_active}
                                                        onChange={ev => setFoodCategory({ ...foodCategory, is_active: Number(ev.target.checked) })}
                                                        type="checkbox"
                                                        id="ckActive" />

                                                    <label className="form-check-label" htmlFor="ckActive">Active</label>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="form-group row">
                                            <label htmlFor="txtArName" className="col-sm-5 col-form-label">Name ( Arabic )</label>
                                            <div className="col-sm-7">

                                                <input type="text"
                                                    value={foodCategory.ar_name}
                                                    onChange={ev => setFoodCategory({ ...foodCategory, ar_name: ev.target.value })}
                                                    className="form-control"
                                                    id="txtArName"
                                                    placeholder="Arabic Name" />
                                            </div>
                                        </div>

                                        <div className="form-group row">
                                            <label htmlFor="txtEnName" className="col-sm-5 col-form-label">Name ( English )</label>
                                            <div className="col-sm-7">
                                                <input type="text"
                                                    value={foodCategory.en_name}
                                                    onChange={ev => setFoodCategory({ ...foodCategory, en_name: ev.target.value })}
                                                    className="form-control"
                                                    id="txtEnName"
                                                    placeholder="English Name" />
                                            </div>
                                        </div>

                                        {/*  Country */}
                                        <div className="form-group row">
                                            <label htmlFor="sctCountries" className="col-sm-5 col-form-label">Company</label>
                                            <div className="col-sm-7">

                                                <select id="sctCountries"
                                                    value={foodCategory.company != null ? foodCategory.company.id : 0}
                                                    onChange={ev => setFoodCategory({ ...foodCategory, company: companies.find(o => o.id === Number(ev.target.value)) })}
                                                    className="form-control form-select form-select-lg"
                                                    aria-label="Default select example">
                                                    <option value={0}>Select Company</option>
                                                    {companies && companies.map(companyRow => <option key={companyRow.id} value={companyRow.id}>{companyRow.en_name}</option>)}
                                                </select>
                                            </div>
                                        </div>

                                    </form>

                                </div>

                                <div className="col-sm-4">
                                    <h6 className="col-sm-12 text-center">Company Logo</h6>
                                    <img ref={imgPreview_Ref}
                                        className="col-sm-12 img-fluid img-thumbnail"
                                        src={foodCategory.img ? foodCategory.img : "/img/Image_not_available.png"}
                                        alt="Company Logo"
                                    />
                                    <button type="button" onClick={onImageSelect} className="btn btn-success col-sm-12 mt-2">{foodCategory && foodCategory.img ? "Change" : "Select"}</button>
                                    <input ref={btnImageSelect_Ref} style={{ display: "none" }} type="file" onChange={onImageSelected} />

                                </div>
                            </div>

                        </div>

                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                            <button type="button" onClick={onSave} className="btn btn-success">{foodCategory && foodCategory.id ? "Save" : "Add"}</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default FoodCategories;