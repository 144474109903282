import React, { useState } from 'react'
import { Link } from "react-router-dom";
import {useMainContext} from "../../Contexts/ContextProvider"
import axiosClient from "../../Helpers/Axios-Client";
import {toast } from 'react-toastify';

function Login() 
{
  const [email,setEmail] = useState("");
  const [password,setPassword] = useState("");

  const {setCredentials} = useMainContext();

  const btnSignIn_OnClick = (e) => {
    e.preventDefault()

    axiosClient.post("/login",{email,password}).then(({data})=> {
    
      process.env.NODE_ENV === "development" && console.log("Init Login",data);
      setCredentials(data.user,data.token);
    
    }).catch(error => {

      if(error.response && error.response.status === 422)
      {
      
        if(error.response.data.errors)
        {
          Object.keys(error.response.data.errors).map(key => (toast.error(error.response.data.errors[key][0])));
        }
        else
        {
          toast.error(error.response.data.message);
        }
      }
      else
      {
        process.env.NODE_ENV === "development" && console.log("Login Error ",error.response.data);
      }

    });
  }

  return (
    
    <div className='hold-transition login-page'>
    
      <div className="login-box">
        <div className="login-logo" >
          <img src="/img/logo.webp" alt="Logo" className="img-fluid" style={{width:"60%"}} />
        </div>

        <div className="card">
          <div className="card-body login-card-body">
            <p className="login-box-msg">Login into your account.</p>

            <form onSubmit={btnSignIn_OnClick}>

              <div className="input-group mb-3">
                <input type="email" 
                className="form-control" 
                placeholder="Email" 
                value={email}
                onChange={ev => setEmail(ev.target.value)}
                />
                <div className="input-group-append">
                  <div className="input-group-text">
                    <span className="fas fa-envelope" />
                  </div>
                </div>
              </div>

              <div className="input-group mb-3">
                <input type="password" 
                className="form-control" 
                placeholder="Password" 
                value={password}
                onChange={ev => setPassword(ev.target.value)}
                />
                <div className="input-group-append">
                  <div className="input-group-text">
                    <span className="fas fa-lock" />
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-12">
                  <button type="submit" className="btn btn-success btn-block">Sign In</button>
                </div>
              
              </div>
            </form>
 
            <p className="mt-3 mb-1 text-center">
              <Link to={"/forgot-password"}>I forgot my password</Link>
            </p>

          </div>
        </div>
      </div>
    </div>
  )
}

export default Login