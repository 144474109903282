import { createContext, useContext, useState } from 'react'


const MainContext =  createContext({
    user:null,
    token:null,
    setUserForCredentials:()=>{},
    setTokenForCredentials:()=>{},
    setCredentials:()=>{}
});

export const ContextProvider = ({children}) => {

    const [user,_setUser] = useState(JSON.parse(localStorage.getItem("ACCESS_USER")));
    const [token,_setToken] = useState(localStorage.getItem("ACCESS_TOKEN"));

    const setCredentials = (user,token) => {

        _setToken(token);
        _setUser(user);

        token ? localStorage.setItem("ACCESS_TOKEN",token) : localStorage.removeItem("ACCESS_TOKEN");
        user ? localStorage.setItem("ACCESS_USER", JSON.stringify(user)) : localStorage.removeItem("ACCESS_USER");        
    }

    const setUserForCredentials = (user) => {
      _setUser(user);

      user ? localStorage.setItem("ACCESS_USER", JSON.stringify(user)) : localStorage.removeItem("ACCESS_USER");        
  }

  const setTokenForCredentials = (token) => {

    _setToken(token);

    token ? localStorage.setItem("ACCESS_TOKEN",token) : localStorage.removeItem("ACCESS_TOKEN");
}

  return (
    <MainContext.Provider value={{user,token,setCredentials,setUserForCredentials,setTokenForCredentials}}>
        {children}
    </MainContext.Provider>
  )
}

export const useMainContext = () =>  useContext(MainContext)

