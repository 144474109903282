import { Link, NavLink } from "react-router-dom";
import { useMainContext } from "../../../Contexts/ContextProvider"

function SideMenu() {
  const { user } = useMainContext();

  return (
    <aside className="main-sidebar sidebar-light-success">
      {/* Brand Logo */}

      <Link to={"/"} className="brand-link text-center" >
        <img src="/img/logo.webp" alt="Logo" className="img-fluid" style={{ width: "70%" }} />
      </Link>

      {/* Sidebar */}
      <div className="sidebar">
        {/* Sidebar Menu */}
        <nav className="mt-2">

          <ul className="nav nav-pills nav-sidebar flex-column" data-widget="treeview" role="menu" data-accordion="false">

            <li className="nav-header">Analytics</li>
            <li className="nav-item">
              <NavLink to={"/"} className="nav-link" ><i className="nav-icon fa-solid fa-tachometer-alt" /><p>Dashboard</p></NavLink>
            </li>

            {(user.role.countries_view || user.role.countries_add  || user.role.countries_edit || user.role.countries_delete ||
              user.role.users_view || user.role.users_add || user.role.users_edit || user.role.users_delete ) ? 
              <li className="nav-header">Administration</li> : ""}


            {(user.role.countries_view || user.role.countries_add || user.role.countries_edit || user.role.countries_delete) ?
              <li className="nav-item">
                <NavLink to={"/dashboard/countries"} className="nav-link"><i className="nav-icon fa-solid fa-flag" /><p>Countries</p></NavLink>
              </li>:""}

              {(user.role.roles_view || user.role.roles_add || user.role.roles_edit|| user.role.roles_delete) ?
              <li className="nav-item">
                <NavLink to={"/dashboard/roles"} className="nav-link"><i className="nav-icon fas fa-users-cog" /><p>Roles</p></NavLink>
              </li>
              : ""}

            {(user.role.users_view  || user.role.users_add  || user.role.users_edit  || user.role.users_delete ) ?
              <li className="nav-item">
                <NavLink to={"/dashboard/users"} className="nav-link"><i className="nav-icon fa-solid fa-users" /><p>Users</p></NavLink>
              </li> : ""}

            <li className="nav-header">Data</li>
            {(user.role.companies_view  || user.role.companies_add  || user.role.companies_edit  || user.role.companies_delete) ?
              <li className="nav-item">
                <NavLink to={"/dashboard/companies"} className="nav-link"><i className="nav-icon fa-solid fa-building" /><p>Companies</p></NavLink>
              </li> : ""}

            {(user.role.printers_view || user.role.printers_add || user.role.printers_edit || user.role.printers_delete) ?
              <li className="nav-item">
                <NavLink to={"/dashboard/printers"} className="nav-link"><i className="nav-icon fa-solid fa-print" /><p>Printers</p></NavLink>
              </li> : ""}

            {(user.role.employees_view || user.role.employees_add || user.role.employees_edit || user.role.employees_delete) ?
              <li className="nav-item">
                <NavLink to={"/dashboard/employees"} className="nav-link"><i className="nav-icon fa-solid fa-people-group" /><p>Employees</p></NavLink>
              </li> : ""}

            {(user.role.food_categories_view || user.role.food_categories_add || user.role.food_categories_edit || user.role.food_categories_delete) ?
              <li className="nav-item">
                <NavLink to={"/dashboard/food-categories"} className="nav-link"><i className="nav-icon fa-solid fa-bowl-food" /><p>Food Categories</p></NavLink>
              </li> : ""}

            {(user.role.food_view || user.role.food_add || user.role.food_edit || user.role.food_delete) ?
              <li className="nav-item">
                <NavLink to={"/dashboard/food"} className="nav-link"><i className="nav-icon fa-solid fa-bowl-rice" /><p>Food</p></NavLink>
              </li> : ""}
          </ul>
        </nav>
        {/* /.sidebar-menu */}
      </div>
      {/* /.sidebar */}
    </aside>

  );
}

export default SideMenu;
