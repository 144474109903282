import {Outlet,Navigate}  from 'react-router-dom'
import {useMainContext } from "../../Contexts/ContextProvider";
import Header from "./Partials/Header"
import SideMenu from "./Partials/SideMenu"
import Footer from "./Partials/Footer"
// import Loading from "./Partials/Loading"
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

 const Master = () => {

  const { user, token } = useMainContext();
  if (!user || !token) return <Navigate to="/login" />

  return (
    <div className="wrapper">
      <Header />
      <SideMenu />
      <Outlet />
      <Footer />
      
      {/* <Loading /> */}
      <ToastContainer />
    </div>

  )
}

export default Master