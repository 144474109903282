import {Outlet,Navigate}  from 'react-router-dom'
import { useMainContext } from "../../Contexts/ContextProvider";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const GuestLayout = () => {
  const { user, token } = useMainContext();
  if (user && token) return <Navigate to="/" />

  return (
    <div className="wrapper">
      <Outlet />

      <ToastContainer />
    </div>
  )
}

export default GuestLayout