import React from 'react'
import { Link } from "react-router-dom";


function ForgotPassword() {

  const btnRequestNewPassword_OnClick = (e) => {
    e.preventDefault();

  }

  return (
   
    <div className='hold-transition login-page'>
      
      <div className="login-box">
        <div className="login-logo">
        <img src="/img/logo.webp" alt="Logo" className="img-fluid" style={{width:"60%"}} />
        </div>

        <div className="card">
          <div className="card-body login-card-body">
            <p className="login-box-msg">Forgot your password ? dont worry,you can request new password easily by this form.</p>

            <form onSubmit={btnRequestNewPassword_OnClick}>

              <div className="input-group mb-3">
                <input type="email" className="form-control" placeholder="Email" />
                <div className="input-group-append">
                  <div className="input-group-text">
                    <span className="fas fa-envelope" />
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-12">
                  <button type="submit" className="btn btn-success btn-block">Request new Password</button>
                </div>
              
              </div>
            </form>
 
            <p className="mt-3 mb-1 text-center">
              <Link to={"/login"}>Back to sign in</Link>
            </p>

          </div>
          {/* /.login-card-body */}
        </div>
      </div>
    </div>
  )
}

export default ForgotPassword