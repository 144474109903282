import { useLayoutEffect, useRef, useState } from "react";
import axiosClient from "../../Helpers/Axios-Client";
import DataTable from 'react-data-table-component';
import Swal from 'sweetalert2'
import { toast } from 'react-toastify';
import { Link } from "react-router-dom";
import { useMainContext } from "../../Contexts/ContextProvider"

function Food() {
    const { user } = useMainContext();

    const initRowValue = {
        id: null,
        is_active: 1,
        code: "",
        img: null,
        ar_name: "",
        en_name: "",
        shelf_life_days: 0,
        shelf_life_hours: 0,
        shelf_life_minutes: 0,
        food_category: null,
    };

    const [foodCategories, setFoodCategories] = useState();
    const [foods, setFoods] = useState();
    const [food, setFood] = useState(initRowValue);

    const openDetailsModal_Ref = useRef();
    const closeDetailsModal_Ref = useRef();

    const [tempImage, setTempImage] = useState(null);
    const btnImageSelect_Ref = useRef();
    const imgPreview_Ref = useRef();

    const columns = [
        {
            name: '#',
            selector: row => row.id,
            sortable: true,
            center: true,
            width: "80px",
        },
        {
            name: 'Active',
            selector: row => row.is_active,
            sortable: true,
            center: true,
            width: "100px",
            cell: row => (row.is_active) ? <span className="badge badge-success" >Active</span> : <span className="badge badge-danger" >Inactive</span>,
        },
        {
            name: 'Code',
            selector: row => row.code,
            sortable: true,
            width: "100px",
        },
        {
            name: 'Image',
            selector: row => row.img,
            sortable: true,
            width: "100px",
            cell: row => <img src={row.img ? row.img : "/img/Image_not_available.png"} className="img-fluid m-2 elevation-2" width="50" height="50" alt="Food" />

        },
        {
            name: 'Name',
            selector: row => row.en_name,
            sortable: true,
        },
        // {
        //     name: 'Days',
        //     selector: row => row.shelf_life_days,
        //     sortable: true,
        //     center: true,
        //     width: "90px",
        // },
        // {
        //     name: 'Hours',
        //     selector: row => row.shelf_life_hours,
        //     sortable: true,
        //     center: true,
        //     width: "90px",
        // },
        // {
        //     name: 'Minutes',
        //     selector: row => row.shelf_life_minutes,
        //     sortable: true,
        //     center: true,
        //     width: "110px",
        // },
        {
            name: 'Shelf Life',
            sortable: true,
            center: true,
            cell: row => {

                let shelfLife = "";

                if(row.shelf_life_days > 0) {
                    shelfLife += row.shelf_life_days + " Days ";
                }

                if(row.shelf_life_hours > 0) {
                    shelfLife += row.shelf_life_hours + " Hours";
                }

                if(row.shelf_life_minutes > 0) {
                    shelfLife += row.shelf_life_minutes + " Minutes";
                }

                return shelfLife;
            }
        },
        {
            name: 'Food Category',
            selector: row => row.food_category,
            sortable: true,
            cell: row => <div>
                <img src={row.food_category.img ? row.food_category.img : "/img/Image_not_available.png"} className="img-fluid m-2 elevation-2" width="45" height="40" alt="Food Category" />
                <span>{row.food_category.en_name}</span>
            </div>
        },
        {
            name: 'Created at',
            selector: row => row.created_at,
            sortable: true,
            center: true,
        },
        {
            name: '',
            selector: row => row.actions,
            center: true,
            width: "300px",
            cell: row => <>
                {user.role && user.role.food_edit ? <button onClick={ev => onView(row)} type="button" className="btn btn-primary btn-sm text-white mr-2" ><i className="fa fa-edit" /> Edit</button> : ""}
                {user.role && user.role.food_delete ? <button onClick={ev => onDelete(row)} type="button" className="btn btn-danger btn-sm text-white mr-2"><i className="fa fa-trash-alt" /> Delete</button> : ""}
            </>,
        },
    ];

    useLayoutEffect(() => {
        getFoods();
        getFoodCategories();
    }, []);

    const getFoodCategories = () => {

        axiosClient.get("/food-categories").then((response) => {

            process.env.NODE_ENV === "development" && console.log("getFoodCategories", response.data);
            setFoodCategories(response.data);

        }).catch((error) => {

            process.env.NODE_ENV === "development" && console.log("getFoodCategories Error", error);

        });

    }

    const getFoods = () => {
        axiosClient.get("/food").then((response) => {

            process.env.NODE_ENV === "development" && console.log("getFoods", response.data);
            setFoods(response.data);

        }).catch((error) => {

            process.env.NODE_ENV === "development" && console.log("getFoods Error", error);
        });

    }

    const onView = (selectedRow) => {

        if (selectedRow) {
            setFood(selectedRow);
        }
        else {
            setFood(initRowValue);
        }

        openDetailsModal_Ref.current.click();
    }

    const onSave = (ev) => {

        if (food.id) {

            const formData = new FormData();
            formData.append("_method", "PUT");
            formData.append("id", food.id);
            formData.append("is_active", food.is_active);
            formData.append("code", food.code);
            tempImage && formData.append("img", tempImage);
            formData.append("ar_name", food.ar_name);
            formData.append("en_name", food.en_name);
            formData.append("shelf_life_days", food.shelf_life_days);
            formData.append("shelf_life_hours", food.shelf_life_hours);
            formData.append("shelf_life_minutes", food.shelf_life_minutes);
            formData.append("food_category[id]", food.food_category != null ? food.food_category.id : null);


            axiosClient.post(`/food/${food.id}`, formData, { "Content-Type": "multipart/form-data" }).then((response) => {

                process.env.NODE_ENV === "development" && console.log("Edit Food Response : ", response);

                setFoods(response.data);
                closeDetailsModal_Ref.current.click();

                Swal.fire({
                    position: 'center',
                    icon: 'success',
                    title: `${food.en_name} has been updated`,
                    showConfirmButton: false,
                    timer: 1000
                });


            }).catch((error) => {

                if (error.response && error.response.status === 422) {

                    error.response.data.errors && Object.keys(error.response.data.errors).map(key => (
                        toast.error(error.response.data.errors[key][0])
                    ));

                }
                else {
                    process.env.NODE_ENV === "development" && console.log("Edit Food Error : ", error.response);
                }

            });
        }
        else {

            const formData = new FormData();
            formData.append("id", food.id);
            formData.append("code", food.code);
            formData.append("is_active", food.is_active);
            tempImage && formData.append("img", tempImage);
            formData.append("ar_name", food.ar_name);
            formData.append("en_name", food.en_name);
            formData.append("shelf_life_days", food.shelf_life_days);
            formData.append("shelf_life_hours", food.shelf_life_hours);
            formData.append("shelf_life_minutes", food.shelf_life_minutes);
            formData.append("food_category[id]", food.food_category != null ? food.food_category.id : null);

            axiosClient.post(`/food`, formData, { headers: { "Content-Type": "multipart/form-data" } }).then((response) => {

                process.env.NODE_ENV === "development" && console.log("Add Food Response : ", response);
                setFoods(response.data);

                closeDetailsModal_Ref.current.click();

                Swal.fire({
                    position: 'center',
                    icon: 'success',
                    title: `${food.en_name} has been added`,
                    showConfirmButton: false,
                    timer: 1000
                });

            }).catch((error) => {

                if (error.response && error.response.status === 422) {

                    error.response.data.errors && Object.keys(error.response.data.errors).map(key => (
                        toast.error(error.response.data.errors[key][0])
                    ));
                }
                else {
                    process.env.NODE_ENV === "development" && console.log("Add Food Error: ",error.response);
                }


            });
        }

    }

    const onDelete = (selectedRow) => {

        Swal.fire({
            title: `Are you sure you want to delete ${selectedRow.en_name} ?`,
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#d33',
            cancelButtonColor: '#28a745',
            confirmButtonText: 'Yes, delete it!',
            reverseButtons: true
        }).then((result) => {

            if (result.isConfirmed) {

                axiosClient.delete(`/food/${selectedRow.id}`).then((response) => {

                    process.env.NODE_ENV === "development" && console.log("Delete Food response :", response);

                    setFoods(response.data);

                    Swal.fire({
                        position: 'center',
                        icon: 'success',
                        title: `${food.ar_name} has been deleted`,
                        showConfirmButton: false,
                        timer: 1000
                    });

                }).catch((error) => {

                    process.env.NODE_ENV === "development" && console.log("Delete Food Error: ", error);
                });

            }
        })

    }

    const onImageSelect = (ev) => {

        btnImageSelect_Ref.current.click();
    }

    const onImageSelected = (ev) => {
        if (ev.target.files.length > 0) {
            setFood({ ...food, img: URL.createObjectURL(ev.target.files[0]) });
            setTempImage(ev.target.files[0]);
        }

    }

    return (
        <div className="content-wrapper">

            <div className="content-header">
                <div className="container-fluid">
                    <div className="row mb-2">
                        <div className="col-sm-6">
                            <h1 className="m-0">Foods</h1>
                        </div>

                        <div className="col-sm-6">
                            <ol className="breadcrumb float-sm-right">
                                <li className="breadcrumb-item"><Link to={"/"} className="text-success">Dashboard</Link></li>
                                <li className="breadcrumb-item active">Foods</li>
                            </ol>

                        </div>

                    </div>

                </div>

            </div>

            <div className="content">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12">
                            <div className="card">
                                <div className="card-header text-right">
                                    {user.role && user.role.employees_add ? <button onClick={ev => onView(null)} type="button" className="btn btn-success btn-lg" ><i className="fa fa-plus-circle" /> New Food</button> : ""}
                                </div>

                                <div className="card-body">
                                    <div className="row ">
                                        <div className="form-group offset-sm-10 col-sm-2 ">
                                            <div className="input-group">
                                                <input type="search" className="form-control " placeholder="Search" />
                                                <div className="input-group-append">
                                                    <button className="btn btn-default">
                                                        <i className="fa fa-search" />
                                                    </button>
                                                </div>
                                            </div>

                                        </div>

                                    </div>

                                    <DataTable className="table table-bordered" columns={columns} data={foods} pagination />

                                </div>
                            </div>

                        </div>

                    </div>
                </div>
            </div>


            <button style={{ display: "none" }} ref={openDetailsModal_Ref} type="button" className="btn btn-success btn-lg" data-toggle="modal" data-target="#DetailsModal"><i className="fa fa-plus-circle" /> </button>
            <div className="modal fade" id="DetailsModal" data-backdrop='static' tabIndex={-1} role="dialog" aria-labelledby="DetailsModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered" role="document">
                    <div className="modal-content">

                        <div className="modal-header">
                            <h5 className="modal-title text-center" id="exampleModalLabel">{food && food.id ? "Edit Food" : "Add Food"}</h5>
                            <button ref={closeDetailsModal_Ref} type="button" className="close" data-dismiss="modal" aria-label="Close" >
                                <span aria-hidden="true">×</span>
                            </button>
                        </div>

                        <div className="modal-body">
                            <div className="row">

                                <div className="col-sm-8">
                                    <form className="form-horizontal">
                                        <div className="form-group row">
                                            <div className="offset-sm-5 col-sm-9">
                                                <div className="form-check">

                                                    <input className="form-check-input "
                                                        checked={food.is_active}
                                                        onChange={ev => setFood({ ...food, is_active: Number(ev.target.checked) })}
                                                        type="checkbox"
                                                        id="ckActive" />

                                                    <label className="form-check-label" htmlFor="ckActive">Active</label>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="form-group row">
                                            <label htmlFor="txtCode" className="col-sm-5 col-form-label">Code</label>
                                            <div className="col-sm-7">
                                                <input type="text"
                                                    value={food.code}
                                                    onChange={ev => setFood({ ...food, code: ev.target.value })}
                                                    className="form-control"
                                                    id="txtCode"
                                                    placeholder="Code" />
                                            </div>
                                        </div>

                                        <div className="form-group row">
                                            <label htmlFor="txtArName" className="col-sm-5 col-form-label">Name ( Arabic )</label>
                                            <div className="col-sm-7">

                                                <input type="text"
                                                    value={food.ar_name}
                                                    onChange={ev => setFood({ ...food, ar_name: ev.target.value })}
                                                    className="form-control"
                                                    id="txtArName"
                                                    placeholder="Arabic name" />
                                            </div>
                                        </div>


                                        <div className="form-group row">
                                            <label htmlFor="txtEnName" className="col-sm-5 col-form-label">Name ( English )</label>
                                            <div className="col-sm-7">

                                                <input type="text"
                                                    value={food.en_name}
                                                    onChange={ev => setFood({ ...food, en_name: ev.target.value })}
                                                    className="form-control"
                                                    id="txtEnName"
                                                    placeholder="English name" />
                                            </div>
                                        </div>

                                        <div className="form-group row">
                                            <label htmlFor="txtShelfLifeDays" className="col-sm-5 col-form-label">Shelf Life Days</label>
                                            <div className="col-sm-7">
                                                <input type="number"
                                                    value={food.shelf_life_days}
                                                    min={0}
                                                    onChange={ev => setFood({ ...food, shelf_life_days: ev.target.value })}
                                                    className="form-control"
                                                    id="txtShelfLifeDays"
                                                    placeholder="Days" />
                                            </div>
                                        </div>

                                        <div className="form-group row">
                                            <label htmlFor="txtShelfLifeHours" className="col-sm-5 col-form-label">Shelf Life Hours</label>
                                            <div className="col-sm-7">

                                                <input type="number"
                                                    value={food.shelf_life_hours}
                                                    min={0}
                                                    max={23}
                                                    onChange={ev => setFood({ ...food, shelf_life_hours: ev.target.value })}
                                                    className="form-control"
                                                    id="txtShelfLifeHours"
                                                    placeholder="Hours" />
                                            </div>
                                        </div>

                                        <div className="form-group row">
                                            <label htmlFor="txtShelfLifeMinutes" className="col-sm-5 col-form-label">Shelf Life Minutes</label>
                                            <div className="col-sm-7">
                                                <input type="number"
                                                    value={food.shelf_life_minutes}
                                                    min={0}
                                                    max={59}
                                                    onChange={ev => setFood({ ...food, shelf_life_minutes: ev.target.value })}
                                                    className="form-control"
                                                    id="txtShelfLifeMinutes"
                                                    placeholder="Minutes" />
                                            </div>
                                        </div>

                                        <div className="form-group row">
                                            <label htmlFor="sctFoodCategories" className="col-sm-5 col-form-label">Food Category</label>
                                            <div className="col-sm-7">

                                                <select id="sctFoodCategories"
                                                    value={food.food_category ? food.food_category.id : 0}
                                                    onChange={ev => setFood({ ...food, food_category: foodCategories.find(o => o.id === Number(ev.target.value)) })}
                                                    className="form-control form-select form-select-lg"
                                                    aria-label="Default select example">
                                                    <option value={0}>Select Category</option>
                                                    {foodCategories && foodCategories.map(foodCategoriesRow => <option key={foodCategoriesRow.id} value={foodCategoriesRow.id}>{foodCategoriesRow.en_name}</option>)}
                                                </select>
                                            </div>
                                        </div>

                                    </form>
                                </div>

                                <div className="col-sm-4">
                                    <h6 className="col-sm-12 text-center">Food Image</h6>
                                    <img ref={imgPreview_Ref}
                                        className="col-sm-12 img-fluid img-thumbnail"
                                        src={food.img ? food.img : "/img/Image_not_available.png"}
                                        alt="Company Logo"
                                    />
                                    <button type="button" onClick={onImageSelect} className="btn btn-success col-sm-12 mt-2">{food && food.img ? "Change" : "Select"}</button>
                                    <input ref={btnImageSelect_Ref} style={{ display: "none" }} type="file" onChange={onImageSelected} />

                                </div>
                            </div>
                        </div>

                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                            <button type="button" onClick={onSave} className="btn btn-success">{food && food.id ? "Save" : "Add"}</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Food;