import { useLayoutEffect, useState } from "react";
import { Link } from "react-router-dom";
import axiosClient from "../../Helpers/Axios-Client";
import { useMainContext } from "../../Contexts/ContextProvider"

function Dashboard() {
  const { user } = useMainContext();
  const [dashboard, setDashboard] = useState();

  useLayoutEffect(() => {
    getDashboard();
  }, []);

  const getDashboard = () => {

    axiosClient.get("misc/dashboard").then((response) => {
      process.env.NODE_ENV === "development" && console.log("Dashboard", response.data);
      setDashboard(response.data);

    }).catch((error) => {

      console.log(error);

    });

  }
  return (
    <div className="content-wrapper">
      <div className="content-header">
        <div className="container-fluid">
          <div className="row mb-2">
            <div className="col-sm-6">
              <h1 className="m-0">Dashboard</h1>
            </div>

            <div className="col-sm-6">
              <ol className="breadcrumb float-sm-right">
                <li className="breadcrumb-item active">Dashboard</li>
              </ol>
            </div>
          </div>
        </div>
      </div>

      <div className="content">
        <div className="container-fluid">
          <div className="row">

            {(user.role != null && user.role.countries_view) ?
              <div className="col-lg-3 col-6">
                <div className="small-box bg-light">
                  <div className="inner">
                    <h3>{dashboard && dashboard.countries ? dashboard.countries : 0}</h3>

                    <p>Countries</p>
                  </div>
                  <div className="icon">
                    <i className="fa-solid fa-flag"></i>
                  </div>
                  <Link to={"/dashboard/countries"} className="small-box-footer">More info <i className="fas fa-arrow-circle-right"></i></Link>
                </div>
              </div> : ""}

            {(user.role != null && user.role.users_view) ?
              <div className="col-lg-3 col-6">
                <div className="small-box bg-light">
                  <div className="inner">
                    <h3>{dashboard && dashboard.users ? dashboard.users : 0}</h3>

                    <p>Users</p>
                  </div>
                  <div className="icon">
                    <i className="fa-solid fa-users"></i>
                  </div>
                  <Link to={"/dashboard/users"} className="small-box-footer">More info <i className="fas fa-arrow-circle-right"></i></Link>
                </div>
              </div> : ""}

            {(user.role != null && user.role.companies_view) ?
              <div className="col-lg-3 col-6">
                <div className="small-box bg-light">
                  <div className="inner">
                    <h3>{dashboard && dashboard.companies ? dashboard.companies : 0}</h3>

                    <p>Companies</p>
                  </div>
                  <div className="icon">
                    <i className="fa-solid fa-building"></i>
                  </div>
                  <Link to={"/dashboard/companies"} className="small-box-footer">More info <i className="fas fa-arrow-circle-right"></i></Link>
                </div>
              </div> : ""}

            {(user.role != null && user.role.printers_view) ?
              <div className="col-lg-3 col-6">
                <div className="small-box bg-light">
                  <div className="inner">
                    <h3>{dashboard && dashboard.printers ? dashboard.printers : 0}</h3>

                    <p>Printers</p>
                  </div>
                  <div className="icon">
                    <i className="fa-solid fa-print"></i>
                  </div>
                  <Link to={"/dashboard/printers"} className="small-box-footer">More info <i className="fas fa-arrow-circle-right"></i></Link>
                </div>
              </div> : ""}

            {(user.role != null && user.role.employees_view) ?
              <div className="col-lg-3 col-6">
                <div className="small-box bg-light">
                  <div className="inner">
                    <h3>{dashboard && dashboard.employees ? dashboard.employees : 0}</h3>

                    <p>Employees</p>
                  </div>
                  <div className="icon">
                    <i className="fa-solid fa-people-group"></i>
                  </div>
                  <Link to={"/dashboard/employees"} className="small-box-footer">More info <i className="fas fa-arrow-circle-right"></i></Link>
                </div>
              </div> : ""}

            {(user.role != null && user.role.food_categories_view) ?
              <div className="col-lg-3 col-6">
                <div className="small-box bg-light">
                  <div className="inner">
                    <h3>{dashboard && dashboard.food_categories ? dashboard.food_categories : 0}</h3>

                    <p>Food Categories</p>
                  </div>
                  <div className="icon">
                    <i className="fa-solid fa-bowl-food"></i>
                  </div>
                  <Link to={"/dashboard/food-categories"} className="small-box-footer">More info <i className="fas fa-arrow-circle-right"></i></Link>
                </div>
              </div> : ""}

            {(user.role != null && user.role.food_view) ?
              <div className="col-lg-3 col-6">
                <div className="small-box bg-light">
                  <div className="inner">
                    <h3>{dashboard && dashboard.food ? dashboard.food : 0}</h3>

                    <p>Food</p>
                  </div>
                  <div className="icon">
                    <i className="fa-solid fa-bowl-rice"></i>
                  </div>
                  <Link to={"/dashboard/food"} className="small-box-footer">More info <i className="fas fa-arrow-circle-right"></i></Link>
                </div>
              </div> : ""}

          </div>
        </div>
      </div>

    </div>)
}

export default Dashboard